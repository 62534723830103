@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components
{
  *
  {
    @apply m-0 p-0 box-border;
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar
  {
    width: 4px;
    height: 5px;
  }
  ::-webkit-scrollbar-track
  {
    @apply bg-black;
  }
  ::-webkit-scrollbar-thumb
  {
    transition: 1s;
    background: #ffffff;
    border-radius: 15px;
  }
  ::selection {
    background-color: #000000;
    color: white;
  }
  ::-moz-selection {
    background-color: #000000;
    color: white;
  }  

  /* SECTIONS */

  .App
  {
    @apply min-h-[100dvh];
  }

  .Navbar
  {
    @apply fixed w-[90%] top-[5%] left-[5%] flex justify-between items-center rounded-2xl z-[999];
  }

  .Footer
  {
    @apply bg-black p-4 pl-4 pr-4 md:pl-20 md:pr-20 w-full;
  }

  .Section
  {
    @apply min-h-[100dvh] flex flex-col justify-center items-center p-10;
  }

  .Content
  {
    @apply bg-[#07080A] w-full;
  }

  .container-grid
  {
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4;
  }

  .hero-video
  {
    @apply absolute top-0 left-0 w-full h-full object-cover z-[1];
  }

  /* GENERAL STYLE */

  .Open
  {
    animation: NavAnimation 0.5s ease-in-out;
  }

  .NavButton
  {
    animation: NavButton 0.3s ease-in-out;
  }

  .Online
  {
    @apply p-2 bg-green-700 rounded-full m-4 absolute bottom-0 right-0;
  }

  .Offline
  {
    @apply p-2 bg-red-700 rounded-full m-4 absolute bottom-0 right-0;
  }

  .Alert
  {
    @apply fixed top-[50%] left-[50%] w-[55%] rounded-2xl bg-green-700 flex justify-center items-center p-2 translate-x-[-50%] translate-y-[-50%] z-[999];
  }

  .AlertProject-Offline
  {
    @apply bg-zinc-900 rounded-2xl p-4 fixed flex justify-between items-center translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] text-red-700 z-[999];
  }

  .AlertProject-Online
  {
    @apply bg-zinc-900 rounded-2xl p-4 fixed flex justify-between items-center translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] text-green-700 z-[999];
  }

  /* ANIMATIONS */

  @keyframes NavAnimation {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

  @keyframes NavButton {
    0% {transform: scale(1);}
    50% {transform: scale(0.5);}
    100% {transform: scale(1);}
  }

}